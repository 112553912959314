import { provideGetSeoGenericCuratorList } from '~/api-core/Seo/genericCuratorList'

export function useGetSeoGenericCuratorList() {
  const nuxtApp = useNuxtApp()

  return useAsyncData(
    'SEO_LISTING',
    () => {
      const fetchSeoGenericList = provideGetSeoGenericCuratorList($coreFetch)
      return fetchSeoGenericList()
    },
    {
      default: () => [],
      getCachedData: (key) =>
        // @ts-expect-error from https://nuxt.com/blog/v3-8#%EF%B8%8F-data-fetching-improvements.
        nuxtApp.payload?.static?.[key] ?? nuxtApp.payload.data[key],
    },
  )
}
